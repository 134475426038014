.login {
	background-image: url("/assets/img/general/loginback.jpg");
	background-position: center center;
	background-size: cover;
	display: block;
	width: 100%;
	height: 100%;
	position: fixed;

	.layout-v2 & {
		background: none;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		height: auto;
		position: static;
		background-color: $purpleV2;

		+ .footer {
			margin-top: 0;
			background-color: $purpleDarkV2;
		}
	}

	.login-wrapper {
		width: 100%;
		height: calc(100% - 100px);
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			background: rgba(255, 255, 255, 0.6);
			border: 1px solid #5a5a5a;
			box-shadow: $box-shadow;
			padding: 50px;
			width: 420px;
			.layout-v2 & {
				border: 0;
				background-color: $orangeV2;
				width: 100%;
				max-width: 500px;
				margin: 0 20px;
			}

			.login-header {
				text-align: center;
				width: 100%;
				position: relative;
			}

			
			a {
				color: $purpleDarkV2;
				font-weight: 700;

				&:hover {
					text-decoration: underline;
				}
			}

			.login-contents {
				margin: 30px 0 20px;

				.title {
					font-weight: 500;
					text-align: center;
					margin: 20px 0;
					.layout-v2 & {
						font-weight: 600;
						text-transform: uppercase;
					}
				}

				form {
					position: relative;
					text-align: center;
					width: 100%;

					div {
						background: #ffffff;
						border: 1px solid #252525;
						border-radius: 50px;
						margin: 10px 4px;
						padding: 6px 10px;
						overflow: hidden;
						.layout-v2 & {
							background: transparent;
							border-color: $purpleDarkV2;
						}

						input {
							font-family: "Gotham Book", sans-serif;
							font-weight: normal;
							border: 0;
							font-size: 12px;
							width: 100%;
							background-color: transparent;
							.layout-v2 & {
								font-family: $uniform;
								color: $purpleDarkV2;
								font-size: 14px;
								font-weight: 500;
								&::placeholder {
									color: $purpleDarkV2;
								}
								&:-internal-autofill-selected {
									background-color: transparent!important;
									color: $purpleDarkV2!important;
								}
							}
						}
					}					
					p {
						text-align: center;
						margin: 20px 0;
						font-size: 14px;
						font-weight: 500;

						input[type="checkbox"] {
							display: none;
						}

						input[type="checkbox"]+label {

							display: inline-block;
							position: relative;
							vertical-align: middle;
							line-height: .75;


							&::before {
								background: #ffffff;
								border: 1px solid #252525;
								content: ' ';
								font-size: 24px;
								position: absolute;
								left: -25px;
								top: -5px;
								height: 20px;
								width: 20px;
							}
						}

						input[type="checkbox"]:checked+label {
							&::before {
								content: 'X';
							}
						}
					}
				}

				.btn {
					text-align: center;
					width: 100%;
					position: relative;
					text-align: center;
					width: 100%;
					position: relative;
					background: none;
					border: none;
					border-radius: 0;
					margin: 0 auto;
					padding: 0;

					.submit {
						font-family: $uniform;
						font-weight: 600;
						background: $purpleDarkV2;
						border: 0;
						border-radius: 50px;
						color: #fff;
						font-size: 14px;
						padding: 10px 35px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						display: block;
						min-width: 130px;
						margin: 0 auto;

						&[disabled] {
							background: #d5d5d5;
							cursor: default;
						}
					}
				}
			}

			.login-footer {
				text-align: center;
				width: 100%;
				position: relative;
				margin-top: 35px;

				img {
					.layout-v2 & {
						max-width: 125px;
					}
				}
				
			}

			.login-error {
				display: none;
				text-align: center;
				width: 100%;
				position: relative;

				.title {
					font-family: Gotham, sans-serif;
					font-weight: 500;
					text-align: center;
					margin: 20px 0;
					font-size: 14px;
					.layout-v2 & {
						font-family: $uniform;
						font-weight: 700;
						font-size: 16px;
						text-transform: uppercase;
					}
				}

				p {
					font-family: Gotham, sans-serif;
					font-weight: 100;
					text-align: center;
					margin: 20px 0;
					font-size: 14px;
					line-height: 20px;
					.layout-v2 & {
						font-family: $uniform;
						font-weight: 500;
					}
				}

				.btn {
					text-align: center;
					width: 100%;
					position: relative;
					text-align: center;
					width: 100%;
					position: relative;
					background: none;
					border: none;
					border-radius: 0;
					margin: 0 auto;
					padding: 0;
					.layout-v2 & {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
}