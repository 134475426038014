// .simulator {
//     padding: 10px 20px 20px;
//     overflow: hidden;
//     display: block;
//     position: relative;
//     width: 100%;
//     box-shadow: $box-shadow;
//     background-color: white;
//     margin-bottom: 15px;
//     h2 {
//         font-family: "Gotham", sans-serif;
//         font-weight: normal;
//         color: $red;
//         font-size: 25px;    
//         padding: 10px 0 20px;
//     }
//     p {
//         font-family: Gotham,sans-serif;
//         font-weight: normal;
//         font-size: 15px;
//         line-height: 1.2;
//         padding: 0 0 20px;
//     }
//     .table-simulator {
//         position: relative;
//         display: block;
//         width: 100%;
//         table {
//             margin: 0 auto;
//             display: table;
//             width: 100%;
//             height: 100%;
//             border-collapse: collapse;
//             border-spacing: 0;
//             thead {
//                 tr {
//                     background-color: $red;
//                     th {
//                         padding: 8px 0;
//                         font-family: "Gotham Book", sans-serif;
//                         font-weight: normal;
//                         font-size: 14px;
//                         color: #ffffff;
//                     }
//                 }
//             }
//             tbody {
//                 tr {
//                     td {
//                         padding: 8px 0;
//                         text-align: center;
//                         font-family: "Gotham Book", sans-serif;
//                         font-weight: normal;
//                         font-size: 14px;
//                         color: #878787;
//                         select {
//                             color: #8c8c8c;
//                         }
//                         input {
//                             text-align: center;
//                         }
// 					}
					
// 					&.row__hidden-clone {
// 						display: none;
// 					}
//                 }
//                 .odd {
//                     background: #f7f7f7;
//                 }
//                 .even {
//                     background: #ececec;
//                 }
//             }
//         }
// 	}	
//     .add-field {
//         position: relative;
//         width: 100%;
//         text-align: right;
//         button {
//             background-color: #8c8c8c;
//             color: #fff;
//             padding: 8px 50px;
//             cursor: pointer;
// 			font-size: 12px;
// 			&.disabled {
// 				background-color: #d5d5d5;
// 				cursor: default;
// 			}
//         }
//     }
//     .last, .points {
//         max-width: 580px;
//         td {
//             width: 50%;
//         }
//     }
//     .last {
//         tr {
//             &:first-child {
//                 td {
//                     &:first-child {
//                         background-color: #c5c5c5;
//                         color: white;
//                     }
//                 }
//             }
//             &:last-child {
//                 td {
//                     &:first-child {
//                         background-color: #b2b2b2;
//                         color: white;
//                     }
//                 }
//             }
//         }
//     }
//     .points {
//         margin-top: 10px !important;
//         td {
//             &:first-child {
//                 background-color: $red;
//                 color: white;
//             }
//             &:last-child {
//                 background: #ececec;
//                 color: #8c8c8c;
//             }
//         }
//     }
//     .btn {
//         position: relative;
//         width: 100%;
//         display: block;
//         height: auto;
//         margin-top: 50px;
//         text-align: center;
//         margin-bottom: 25px;
//         a {
//             font-family: Gotham,sans-serif;
//             font-weight: 500;
//             background: $red;
//             border: 0;
//             border-radius: 50px;
//             color: #fff;
//             font-size: 14px;
//             padding: 10px 34px 8px;
//             cursor: pointer;
//             text-decoration: none;
//             text-transform: uppercase;
//             margin: 0 10px;
//             text-decoration: none;
//         }
// 	}
	
// 	select {
// 		&::-ms-expand {
// 			display: block;
// 		}
// 	}
// }
// .featured-prizes {
// 	display: none;
// }

// .featured-prizes {
//     padding: 10px 20px 20px;
//     overflow: hidden;
//     display: block;
//     position: relative;
//     width: 100%;
//     box-shadow: $box-shadow;
//     background-color: white;
//     display: none;
//     h2 {
//         font-family: "Gotham", sans-serif;
//         font-weight: normal;
//         color: $red;
//         font-size: 25px;    
//         padding: 10px 0 20px;
//     }
//     .prizes {
//         display: block;
//         width: 100%;
//         padding: 30px 0;
//         .item {
//             font-family: "Gotham Book", sans-serif;
//             font-weight: normal;
//             font-size: 12px;
//             display: block;
//             float: left;
//             padding: 0 30px;
//             width: 25%;
//             position: relative;
//             a {
//                 text-decoration: none;
//                 color: #878787;
//                 text-align: center;
//                 display: block;
//                 img {
//                     max-height: 145px;
//                 }
//                 .name {
//                     color: #878787;
//                     line-height: 1.2;
//                     margin-top: 10px;
//                     text-transform: uppercase;
//                     text-align: center;
//                 }
//                 .brand {
//                     color: #878787;
//                     line-height: 1.2;
//                     text-transform: uppercase;
//                     text-align: center;
//                 }
//                 .points {
//                     font-family: 'Gotham', sans-serif;
//                     font-weight: 500;
//                     font-size: 14px;
//                     margin-top: 10px;
//                     color: #878787;
//                     text-align: center;
//                 }
//             }
//         }
//     }
// }

.simulator {
	@include size(100%, auto);
	@include padding($short: 40px 30px);
	background: #ffffff;
	box-shadow: 5px 5px 20px 0 rgba(173,173,173,.15);
	color: $gray;

	&__title {
		font-size: 31px;
		font-weight: 500;
		color: $gray;
		text-align: center;
		@include margin($bottom: 30px);

		.simulator__result & {
			@include margin($top: 80px)
		}
	}

	&__text {
		font-size: 16px;
		font-weight: 500;
		line-height: 19px;
		text-align: center;

		.simulator__result-savings & {
			@include margin($top: 40px);
		}

		.simulator__result-message & {
			@include margin($top: 20px);
		}

		&--big {
			font-size: 40px;
			font-weight: 700;
			line-height: 48px;
		}

		&--extra-big {
			font-size: 49px;
			font-weight: 700;
			line-height: 59px;
			.simulator__result-savings & {
				text-align: center;
			}
		}
	}

	&__row {
		@include size(100%, auto);
		display: flex;
		flex-wrap: wrap;
		@include padding($short: 25px 0);

		&:last-of-type {
			@include padding($bottom: 0);
		}

		&--border-bottom {
			border-bottom: 1px solid #d9d9d9;
		}
	}

	&__column {
		@include size(50%, auto);
		@include padding($right: 20px);
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		&:nth-of-type(2n){
			@include padding($left: 20px, $right: 0);
			border-left: 1px solid #d9d9d9;
		}
	}

	&__result {
		&-container {
			@include size(100, auto);
			@include margin($top: 40px);
		}
		&-clients {
			display: none;
		}
		&-message {
			display: none;
		}
	}

	// .form {
	// 	@include margin($bottom: 80px)
	// }
}


