*,
*::before,
*::after {
	box-sizing: border-box; //todo mixin
}

html {
	height: 100%;
	min-height: 100%;
	position: relative;
}

body {
	font-family: Gotham, sans-serif;
    font-weight: 300;
    min-height: 100vh;
	width: 100%;
	background-color: #ececec;
	position: relative;
	&.layout-v2 {
		background-color: $orangeV2;
		font-family: $uniform;
		display: flex;
		flex-direction: column;
	}
}

.wrapper {
    position: relative;
    display: block;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
	height: 100%;
	.layout-v2 & {
		flex-grow: 1;
	}
	.section {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 40px;
		}
	}
}

.clear {
	clear: both;
}

//remove default styles
input,
button,
select,
textarea {
	border: 0;
	outline: 0;
	background: transparent;
	@include margin($short: 0);
	@include padding($short: 0);
	font-family: inherit;
	font-size: inherit;
	appearance: none;
}

select {
	&::-ms-expand {
		display: none;//remove IE default arrow
	}

	&::-ms-value {
		background: none;
		color: black;//change color according to project
	}
}

strong{
	font-weight: 700;
}

em {
	font-style: italic;
}

img {
	max-width: 100%;
    vertical-align: middle;
}

p {
	color: #5a5a5a;
	.layout-v2 & {
		color: $purpleDarkV2;
	}
}

.page404 {
	padding: 10px 20px 20px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
	background-color: white;
	h2 {
        font-family: "Gotham", sans-serif;
        font-weight: normal;
        color: $red;
        font-size: 25px;    
        padding: 10px 0 20px;
    }
    p {
        font-family: "Gotham Book", sans-serif;
        font-weight: normal;
        font-size: 14px;
        color: #878787;
        line-height: 18px;
    }
}

.order-error {
	color: $white;
	margin-bottom: 30px;
}