.header {
	min-height: 75px;
	position: relative;
	width: 100%;
	z-index: 2;
	padding: 10px;
	background: #ececec;
	max-width: 1140px;
	height: 100%;
	margin: 0 auto;

	.layout-v2 & {
		background-color: $orangeV2;
		padding: 0;
		font-family: $uniform;

	}

	.header-wrapper {
		position: relative;
		width: 100%;
		height: 100%;

		.layout-v2 & {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
		}

		.logo {
			position: relative;
			width: 25%;
			display: block;
			float: left;
			text-align: center;

			a {
				img {
					height: 56px;
				}
			}
		}

		.menu {
			position: relative;
			width: 60%;
			display: block;
			float: left;
			top: 15px;
			font-family: 'Gotham Book', Arial, Helvetica, sans-serif;

			ul {
				align-items: center;
				display: flex;
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					align-items: center;
					border-left: 1px solid #5a5a5a;
					display: flex;
					height: 20px;
					margin: 0;
					position: relative;

					a {
						display: inline-block;
						width: 100%;
						height: 100%;
						padding: 3px 10px;
						line-height: 18px;
						color: #5a5a5a;
						text-decoration: none;
						font-size: 13px;
						font-weight: 400;

						&:hover {
							color: $red;
						}

						i {
							font-family: "Font Awesome 5 Free";
						}
					}

					&:first-child {
						border-left: 0;

						a {
							color: $red;
						}
					}

					&:nth-child(2) {
						&:hover {
							.submenu {
								display: block;
							}
						}
					}

					&:last-child {
						a {
							&::before {
								font-family: "Font Awesome 5 Free";
								content: "\f2f5";
								margin-right: 5px;
							}
						}
					}

				}
			}
		}

		.cc-logo {
			position: relative;
			width: 15%;
			display: block;
			float: right;
			text-align: center;
		}

		.menu-trigger {
			display: none;
			float: left;
			width: 35px;
			height: 100%;
			position: absolute;
			top: 15px;
			cursor: pointer;
			transition: 0.4s;

			ul {
				li {
					background-color: rgb(140, 140, 140);
					position: relative;
					width: 100%;
					height: 4px;
					margin-bottom: 3px;
				}

				.change {
					&:first-child {
						opacity: 0;
					}

					&:nth-child(2) {
						transform: rotate(-45deg) translate(3px, 2px);
					}

					&:nth-child(3) {
						transform: rotate(45deg) translate(-3px, -8px);
					}

					&:last-child {
						opacity: 0;
					}
				}
			}
		}
	}

	.responsive-menu {
		display: none;
		position: relative;
		width: 100%;
		height: auto;
		text-align: center;
		margin-top: 60px;

		ul {
			li {
				a {
					color: #252525;
					text-decoration: none;
					display: inline-block;
					height: 100%;
					line-height: 18px;
					padding: 16px 0;
					width: 100%;

					&:hover {
						color: $red;
					}
				}

				ul {
					li {
						a {
							font-weight: 500;
							font-size: 14px;
							padding: 0 0 10px;

							&:hover {
								color: $red;
							}
						}
					}
				}
			}
		}
	}

	.active {
		display: block;
	}

	&-logo {
		display: flex;
		align-items: center;
		width: 100%;
		height: auto;

		&:nth-of-type(2) {
			min-height: 45px;
			max-width: 200px;
			margin-bottom: -10px;

			@media screen and (min-width: 1024px) {
				max-width: 240px;
			}

			@media screen and (min-width: 1200px) {
				max-width: 280px;
			}
		}

		&:last-of-type {
			display: none;

			@media screen and (min-width: 576px) {
				max-width: 100px;
				display: block;
			}

			@media screen and (min-width: 1200px) {
				max-width: 125px;
			}
		}
	}

	&-menu {
		display: none;
		flex-grow: 1;
		flex-basis: 100%;
		order: 3;
		margin-top: 25px;

		@media screen and (min-width: 1024px) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			align-items: center;
			flex-basis: auto;
			order: 0;
			margin-top: 0;
		}

		&-item {
			@include relative;
			@include padding($short: 10px 0);
			color: $white;
			font-family: $uniformCond;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid rgba($white, .5);

			@media screen and (min-width: 1024px) {
				@include padding($short: 10px 5px);
				margin-right: 10px;
				border-top: 0;
			}

			&:last-of-type {
				margin-right: 0;
				border-bottom: 1px solid rgba($white, .5);

				@media screen and (min-width: 1024px) {
					border-bottom: 0;
				}
			}

			&.has-submenu {
				flex-wrap: wrap;
				padding-bottom: 0;

				@media screen and (min-width: 1024px) {
					padding-bottom: 10px;
				}

				&:hover {
					.header-submenu {
						@media screen and (min-width: 1024px) {
							display: block;
						}
					}
				}
			}

			a {
				text-decoration: none;
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					margin-left: 5px;
				}
			}

			img {
				margin-left: 5px;
			}

			&:first-of-type {
				display: none;

				@media screen and (min-width: 1024px) {
					display: flex;
				}
			}
		}
	}

	&-submenu {
		background-color: $orangeV2;
		width: 100%;

		@media screen and (min-width: 1024px) {
			display: none;
			@include absolute(top 100% left 0);
			z-index: 1
		}

		&-item {
			@include padding($short: 10px);
			background-color: rgba($white, .1);

			@media screen and (min-width: 1024px) {
				border-top: 1px solid rgba($white, .5);
			}

			a {
				text-decoration: none;
			}

			&:first-of-type {
				margin-top: 10px;

				@media screen and (min-width: 1024px) {
					margin-top: 0;
				}
			}

			&:nth-of-type(2n+1) {
				background-color: rgba($white, .2);
			}
		}
	}

	&-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 15px;

		&:last-of-type {
			margin-bottom: 0;
			@include relative;
		}

		&:first-of-type {
			justify-content: space-between;
		}
	}

	&-status {
		justify-content: space-between;
		background-color: $purpleDarkV2;
		@include padding($short: 5px 20px);
		font-size: 14px;
		font-weight: 600;
		color: $orangeV2;
		text-transform: uppercase;

		span {
			color: $white;
		}

		a {
			color: $white;
		}
	}

	&-toggle {
		@include size (30px, 21px);
		@include relative;

		@media screen and (min-width: 1024px) {
			display: none;
		}

		span {
			@include absolute(top 0 left 0);
			@include size(100%, 3px);
			background-color: $white;
			opacity: 1;
			transition: all .3s ease;

			&:nth-of-type(2) {
				top: 9px
			}

			&:last-of-type {
				top: 18px;
			}
		}

		&.close {
			span {
				&:first-of-type {
					transform: rotate(45deg) translate(6px, 7px);
				}

				&:nth-of-type(2) {
					opacity: 0;
				}

				&:last-of-type {
					transform: rotate(-45deg) translate(5px, -7px);
				}
			}
		}
	}
}