.shortcuts {
    position: relative;
    width: 100%;
    display: block;
    ul {  
        display: flex;
        height: 280px;
        background: $red;
        padding-left: 0;
        li {
          list-style: none;
          flex: 0 25%;
          cursor: pointer;
          &:hover {
            background: #cc0001;
          }
          a {
			height: 100%;
			flex-direction: column;
			display: flex;
			align-items: center;
			justify-content: center;
              text-align: center;
              text-decoration: none;
              i {
                color: white;
                font-size: 50px;
                margin-bottom: 20px;
                }
                p {
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }
}