.text {
	width: 100%;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	color: $gray;
	text-decoration: none;
	.title-columns & {
		.layout-v2 & {
			font-size: 18px;
			line-height: 20px;
			text-transform: uppercase;
		}
	}
	&--semibold {
		font-weight: 500;
	}
	&--bold {
		font-weight: 700;
	}
	&--white {
		color: $white;
	}
	&--big {
		font-size: 61px;
	}
	&--medium {
		font-size: 24px;
		line-height: 28px;
	}
	&--small {
		font-size: 12px;
		line-height: 14px;
	}
	&--red {
		color: $red-light;
		.layout-v2 & {
			color: $bordeauxV2
		}
	}
	&--green {
		color: $green;
	}
	&--orange {
		color: $orange;
		.layout-v2 & {
			color: $orangeV2;
		}
	}
	&--gray {
		color: $gray-light
	}
	&--purple {
		color: $purpleDarkV2;
	}
	&--center {
		text-align: center;
	}
	&--extra-condensed {
		font-family: $uniformExCond;
	}
	&--condensed {
		font-family: $uniformCond;
	}
}

.title {
	font-size: 31px;
	line-height: 37px;
	color: $gray;
	font-weight: 500;
	width: 100%;
	@include margin($bottom: 24px);
	.layout-v2 & {
		font-family: $uniform;
	}
	&--center {
		text-align: center;
		&.title--underline {
			&::after {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	&--condensed {
		.layout-v2 & {
			font-family: $uniformCond;
		}
	}
	&--extra-condensed {
		.layout-v2 & {
			font-family: $uniformExCond;
		}
	}
	&--bold {
		font-weight: 700;
	}
	&--black {
		font-weight: 900;
	}
	&--medium {
		font-size: 25px;
		line-height: 30px;
	}
	&--small {
		font-size: 16px;
		line-height: 22px;
	}
	&--big {
		font-size: 40px;
		line-height: 48px;
	}
	&--white {
		color: $white;
	}
	&--purple {
		color: $purpleDarkV2;
	}
	&--underline {
		@include relative;
		@include margin($bottom: 40px);
		&::after {
			content: '';
			@include absolute(bottom -20px left 0);
			@include size(80px, 14px);
			background: url('/assets/img/general/separador.svg') no-repeat center / cover;
		}
	}
}

.margin {
	&--bottom {
		@include margin($bottom: 40px);
		&-small {
			@include margin($bottom: 10px);
		}
		&-null {
			@include margin($bottom: 0);
		}
	}
}