[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .icon-speaker:before {
	content: "\e900";
  }
  .icon-catalog:before {
	content: "\e901";
  }
  .icon-book:before {
	content: "\e902";
  }
  .icon-calculator:before {
	content: "\e903";
  }