.catalog {
    padding: 10px 20px 20px;
    overflow: hidden;
    display: block;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;
    background-color: white;
    h2 {
        font-family: "Gotham", sans-serif;
        font-weight: normal;
        color: $red;
        font-size: 25px;    
        padding: 10px 0 20px;
    }
    .catalog-container {
        width: 100%;
        padding: 0;
        a {
            text-decoration: none;
        }
        .products-banner {
            cursor: pointer;
            background-position: center center;
            background-size: cover;
            border: 0;
            height: 190px;
            padding: 0;
            position: relative;
            margin: 0 0 20px;
            width: 100%;
            .content-left {
                background: rgba(255, 255, 255, 0.7);
                bottom: 0;
                left: 0;
                width: 25%;
                padding: 20px 30px 20px 20px;
                position: absolute;
                text-align: left;
                h3 {
                    font-family: Gotham, sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    color: #5a5a5a;
                }
                div {
                    p {
                        font-family: "Gotham Book", sans-serif;
                        font-weight: normal;
                        font-size: 14px;
                        color: #5a5a5a;
                    }
                    &:after {
                        background: url("/assets/img/general/home-red-next.png");
                        content: ' ';
                        height: 21px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 21px;
                    }
                }
            }
            h2 {
                font-family: Gotham, sans-serif;
                font-weight: bold;
                font-size: 30px;
                color: #ffffff;
                text-transform: uppercase;
                position: absolute;
                bottom: 10px;
                right: 20px;
            }
        }
        .experiences-banner {
            cursor: pointer;
            background-position: center center;
            background-size: cover;
            border: 0;
            height: 190px;
            padding: 0;
            position: relative;
            margin: 0 0 20px;
            width: 100%;
            .content-left {
                background: rgba(255, 255, 255, 0.7);
                bottom: 0;
                left: 0;
                width: 25%;
                padding: 20px 30px 20px 20px;
                position: absolute;
                text-align: left;
                h3 {
                    font-family: Gotham, sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    color: #5a5a5a;
                }
                div {
                    p {
                        font-family: "Gotham Book", sans-serif;
                        font-weight: normal;
                        font-size: 14px;
                        color: #5a5a5a;
                    }
                    &:after {
                        background: url("/assets/img/general/home-red-next.png");
                        content: ' ';
                        height: 21px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 21px;
                    }
                }
            }
            h2 {
                font-family: Gotham, sans-serif;
                font-weight: bold;
                font-size: 30px;
                color: #ffffff;
                text-transform: uppercase;
                position: absolute;
                bottom: 10px;
                right: 20px;
           }   
        }
    }
}