.products {
	padding: 10px 20px 20px;
	display: block;
	position: relative;
	width: 100%;
	box-shadow: $box-shadow;
	background-color: white;

	h2 {
		font-family: "Gotham", sans-serif;
		font-weight: normal;
		color: $red;
		font-size: 25px;
		padding: 10px 0 20px;
	}

	.search-container {
		width: 100%;
		padding: 0 0 20px 0;
		position: relative;

		.search {
			display: inline-block;
			padding: 0 10px 0 0;
			width: 24%;
			border: 1px solid #5a5a5a;
			border-radius: 20px;
			padding: 2px 10px;
			position: relative;

			form {
				input {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					color: #252525;
					background: none;
					border: 0;
					padding: 0;
					margin: 0;
					width: 100%;
				}

				button {
					font-size: 14px;
					background: none;
					border: 0;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 2px;
					right: 10px;
				}
			}
		}

		.filters-bar {
			display: none;
			font-family: "Gotham Book", sans-serif;
			font-weight: normal;
			font-size: 12px;
			text-align: right;
			width: 75%;

			span {
				color: #878787;
			}

			ul {
				display: inline-block;
				margin: 0 0 0 6px;

				li {
					display: inline-block;

					a {
						background: #8e8e8e;
						border: 0;
						border-radius: 3px;
						color: $white;
						cursor: pointer;
						margin: 0 4px;
						padding: 2px 8px 4px;
						text-decoration: none;
					}

					&:last-child {
						a {
							margin: 0 0 0 4px;
						}
					}
				}

				.selected {
					a {
						background: $red;
					}
				}
			}

		}
	}

	aside {
		width: 25%;
		margin: 0 10px 0 0;

		.menu {
			.head {
				position: relative;

				h2 {
					background: $red;
					font-family: Gotham, sans-serif;
					font-weight: 500;
					font-size: 14px;
					color: $white;
					padding: 8px;
					cursor: pointer;
				}

				.products-link,
				.experiences-link {
					display: inline-block;
					position: absolute;
					top: 5px;
					color: $white;
					font-size: 16px;
					background: none;
					border: 0;
					margin: 0 2px;
					padding: 2px;
					opacity: .6;
					cursor: pointer;
				}

				.products-link {
					right: 28px;
				}

				.experiences-link {
					right: 6px;
				}

				.drop {
					display: none;
					position: absolute;
					top: 0;
					color: $white;
					font-size: 20px;
					background: 0 0;
					border: 0;
					margin: 0 2px;
					padding: 0;
					cursor: pointer;
					right: 5px;
				}

				.selected {
					opacity: 1;
				}

				ul {
					border: 1px solid #8e8e8e;
					border-top: 0;
					width: 100%;

					li {
						display: block;
						border-top: 1px solid #8e8e8e;
						position: relative;

						&.subcategorie {
							text-indent: 1em
						}

						&.sub-subcategorie {
							text-indent: 2em
						}

						&:first-child {
							border-top: 0;
						}

						a {
							font-family: "Gotham Book", sans-serif;
							font-weight: normal;
							font-size: 12px;
							color: #878787;
							background: 0;
							border: 0;
							cursor: pointer;
							display: block;
							margin: 0;
							padding: 6px 4px 6px 18px;
							text-align: left;
							width: 100%;
							text-decoration: none;

							&:hover {
								color: $red;
							}

							span {
								margin-left: 5px;
							}
						}
					}

					.hidden {
						display: none;
					}
				}
			}
		}
	}

	.filters-bar-responsive {
		width: 50%;
		display: none;

		select {
			color: $white;
			background-color: #5a5a5a;
			padding: 6px 10px;
			width: 100%;
			position: relative;
			display: block;
			font-weight: 100;
		}
	}

	&__container {
		display: block;
		clear: both;

		@media (min-width: 1200px) {
			display: flex;
		}
	}


	.main {
		width: 75%;
		padding: 0 0 0 10px;

		.highlights {
			position: relative;
			width: 100%;

			.img-block {
				background-image: url("/assets/img/general/products-banner-small.jpg");
				background-position: center center;
				background-size: cover;
				border: 0;
				height: 255px;
				padding: 0;
				position: relative;
				margin: 0 0 20px;
				width: 100%;

				.text-container {
					top: 0;
					right: 0;
					padding: 20px 30px 20px 20px;
					position: absolute;
					text-align: right;

					h2 {
						font-family: Gotham, sans-serif;
						font-weight: bold;
						font-size: 30px;
						color: $white;
						text-transform: uppercase;
						margin-bottom: 10px;
						padding: 0;
					}

					p {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 14px;
						color: $white;
					}
				}
			}

			.products-block {

				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.product {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					width: 33.33%;
					text-align: center;
					margin-bottom: 20px;

					a {
						background: none;
						border: 0;
						cursor: pointer;
						padding: 0;
						margin: 0;
						text-align: center;
						text-decoration: none;
						display: flex;
						justify-content: space-between;
						flex-direction: column;
						align-items: center;
						height: 100%;

						.name {
							color: #252525;
							line-height: 1.2;
							margin-top: 10px;
							text-transform: uppercase;
						}

						.description {
							color: #878787;
							line-height: 1.2;
							text-transform: uppercase;
						}

						.points {
							font-family: Gotham, sans-serif;
							font-weight: 500;
							font-size: 14px;
							margin-top: 10px;
							color: #252525;
						}

						img {
							height: 150px;
							object-fit: contain;
						}
					}
				}
			}
		}
	}

	.table {
		border-right: 1px solid #8e8e8e;
		display: none;

		.table-header {
			background: #8e8e8e;
			padding: 4px 8px;

			.breadcrumbs {
				display: inline-block;

				li {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					color: $white;
					font-size: 12px;
					display: inline-block;
					cursor: pointer;
					// &::after {
					//     content: '>';
					//     margin: 0 4px;
					// }
					// &:last-child {
					//     &::after {
					//         content: ' ';
					//     }
					// }
				}
			}

			.favorites-widget {
				float: right;

				a {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					color: $white;
					font-size: 12px;
					background: none;
					border: 0;
					margin: 0;
					padding: 0;
					text-decoration: none;

					&::before {
						content: '\f005';
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
						font-size: 14px;
						color: $white;
						margin-right: 5px;
					}
				}
			}
		}

		.product-list {
			border-left: 1px solid #8e8e8e;
			display: table;
			width: 100%;
			table-layout: fixed; //FIX IE

			.item {
				float: left;
				height: 125px;
				width: 50%;
				border-right: 1px solid #8e8e8e;
				border-bottom: 1px solid #8e8e8e;
				cursor: pointer;
				position: relative;

				.item-image {
					overflow: hidden;
					float: left;
					height: 100%;
					width: 40%;
					position: relative;
					z-index: 0;
					text-align: center;

					img {
						height: 95%;
						object-fit: contain;
					}
				}

				.item-contents {
					font-family: Gotham, sans-serif;
					font-weight: 500;
					font-size: 14px;
					float: left;
					height: 100%;
					padding: 16px 10px 0;
					width: 60%;

					.item-name {
						color: #5a5a5a;
						line-height: 1.2;
						text-transform: uppercase;
					}

					.item-brand {
						color: #878787;
						line-height: 1.2;
						text-transform: uppercase;
					}

					.item-rate {
						color: #5a5a5a;
						font-size: 10px;
						margin-top: 10px;
					}

					.item-points {
						color: #5a5a5a;
						font-size: 14px;
						margin-top: 10px;

						span {
							font-size: 18px;
						}
					}
				}

				&:hover {
					border: 2px solid $red;
				}
			}

			.empty {
				cursor: default;

				&:hover {
					border: 1px solid $white;
					border-bottom: 1px solid #8e8e8e;
					border-right: 1px solid #8e8e8e;
				}
			}
		}

		.paginator {
			border: 1px solid #8e8e8e;
			margin-top: -1px;
			margin-right: -1px;
			padding: 20px 10px;

			span {
				font-family: "Gotham Book", sans-serif;
				font-weight: normal;
				font-size: 12px;
				color: #878787;
				display: inline-block;
				margin-right: 14px;
			}

			ul {
				display: inline-block;

				li {
					display: inline-block;
					margin: 0 3px;

					a {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 10px;
						color: $white;
						background: #e0e0e0;
						border: 0;
						cursor: pointer;
						margin: 0;
						padding: 3px 6px 2px;
						min-width: 20px;
						text-decoration: none;
					}
				}

				.selected {
					a {
						background: $red;
					}
				}
			}
		}
	}

	.product-detail {
		position: relative;
		width: 100%;
		display: none;

		.product-detail-header {
			border: 1px solid #8e8e8e;
			padding: 4px 8px 6px;

			h2 {
				font-family: Gotham, sans-serif;
				font-weight: 500;
				font-size: 14px;
				color: #5a5a5a;
				text-transform: uppercase;
				display: inline-block;
				margin-top: 3px;
				margin-bottom: 0;
				width: auto;
				padding: 0;
			}

			.favorites-widget {
				float: right;

				a {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					color: #252525;
					font-size: 12px;
					background: none;
					border: 0;
					margin: 0;
					padding: 0;
					text-decoration: none;

					&::before {
						content: '\f005';
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
						font-size: 14px;
						color: #252525;
						margin-right: 5px;
					}
				}
			}
		}

		.product-detail-container {
			border: 1px solid #8e8e8e;
			border-top: 0;
			display: flex;
			padding: 20px;

			.product-detail-image {
				width: calc(100%/3);
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					max-width: 200px; //FIX IE
				}
			}

			.product-detail-description {
				width: 33.33%;
				flex: 1;
				min-height: 0;
				min-width: 0;
				padding: 20px;

				div {
					height: 100%;

					p {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						line-height: 1.2;
					}
				}
			}

			.product-detail-data {
				width: 33.33%;
				position: relative;

				.data-reference {
					font-family: Gotham, sans-serif;
					font-weight: 500;
					font-size: 14px;
					color: #252525;
					padding: 20px 20px 20px 0;
					position: absolute;
					top: 0;
				}

				.data-wrapper {
					padding: 20px 20px 20px 0;
					width: 100%;

					.points {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						font-size: 24px;
						color: #252525;
						line-height: 1.2;
					}

					.stock {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						color: #878787;
						line-height: 1.2;

						span {
							font-weight: bold;
							color: #252525;
						}
					}

					.available-points {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						color: #878787;
						line-height: 1.2;
						margin-top: 20px;

						span {
							font-weight: bold;
							color: #252525;
						}
					}

					.request-rate {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						font-size: 10px;
						color: #252525;
						line-height: 1.2;

						span {
							display: inline-block;
						}
					}

					.change-points {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						background: #ed1c24;
						border: 0;
						border-radius: 50px;
						color: $white;
						font-size: 14px;
						padding: 10px 34px 8px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						margin-top: 10px;
						position: relative;
						text-align: left;
						width: 100%;
						padding-left: 20px;
						display: block;

						span {
							font-weight: 600;
							margin-left: 5px;
						}

						&::after {
							content: '\f291';
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							font-size: 14px;
							color: $white;
							position: absolute;
							right: 15px;
						}
					}

					.add-favorite {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						background: #8e8e8e;
						border: 0;
						border-radius: 50px;
						color: $white;
						font-size: 14px;
						padding: 10px 34px 8px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						margin-top: 10px;
						position: relative;
						text-align: left;
						width: 100%;
						padding-left: 20px;
						display: block;

						span {
							font-weight: 600;
							margin-left: 5px;
						}

						&::after {
							content: '\f005';
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							font-size: 14px;
							color: $white;
							position: absolute;
							right: 15px;
						}
					}

					.favorite {
						background: #ececec;
					}
				}
			}
		}

		.product-detail-other-images {
			border: 1px solid #8e8e8e;
			border-top: 0;
			height: 150px;
			overflow: hidden;
			position: relative;

			.container {
				width: 100%;
				height: 100%;

				.slider {
					transform: translateX(0px);
				}
			}
		}
	}

	.product-order {
		border: 1px solid #8e8e8e;
		padding: 30px;
		display: none;

		.product-order-header {
			ul {
				align-items: stretch;
				border: 1px solid #8e8e8e;
				display: flex;
				justify-content: space-around;
				width: 100%;

				li {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					color: #878787;
					font-size: 14px;
					flex: 100%;
					padding: 12px 0 10px;
					position: relative;
					text-align: center;

					&::after {
						content: '>';
						width: 20px;
						height: 37px;
						position: absolute;
						top: 0;
						right: 5px;
						font-size: 35px;
						font-family: monospace;
						color: #878787
					}

					&:last-child {
						&::after {
							content: ' ';
						}
					}
				}

				.selected {
					font-family: Gotham, sans-serif;
					font-weight: 500;
					color: #ed1c24;
				}
			}
		}

		.product-order-step {
			.information {
				margin-top: 20px;

				.information-header {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					color: $white;
					background: #8e8e8e;
					padding: 10px 10px;
				}

				.information-content {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					line-height: 1.2;
					color: #252525;
					border-top: 0;
					padding: 10px 10px;
					background: #c8c8c8;
					border: 0;

					p {
						font-family: Gotham, sans-serif;
						font-weight: 600;

						&:last-child {
							font-family: Gotham, sans-serif;
							font-weight: 100;
						}
					}
				}
			}

			.order {
				margin-top: 20px;

				.order-header {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					color: $white;
					background: #8e8e8e;
					padding: 10px 10px;
				}

				.order-content {
					display: flex;
					overflow: hidden;
					padding: 20px;
					border: solid #878787;
					border-width: 0 1px 1px;

					img {
						width: 100%; //FIX IE
					}

					div {
						width: 100%;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0 10px;
					}

					.name {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						color: #252525;
						text-transform: uppercase;
					}

					.points {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						font-size: 12px;
						color: #252525;
					}
				}
			}

			.address {
				margin-top: 20px;

				.address-header {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					color: $white;
					background: #8e8e8e;
					padding: 10px 10px;
				}

				.address-wrapper {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					line-height: 1.2;
					color: #252525;
					border: 0;
					border-top: 0;
					padding: 10px 10px;
					background: #ececec;

					.address-content {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 14px;
						display: flex;

						div {
							flex: 100%;
							padding: 10px 0;

							p {
								font-weight: 500;

								span {
									font-weight: 100;
								}
							}
						}
					}

					.checkboxes {
						margin: 20px 0 10px;
						text-align: center;

						.checkboxes-wrapper {
							display: inline-block;
							text-align: left;

							input[type="checkbox"] {
								display: none;

								&:checked {
									+label {
										&::before {
											content: 'X';
										}
									}
								}

								+label {
									display: inline-block;
									position: relative;
									vertical-align: middle;
									line-height: .75;
									font-family: "Gotham Book", sans-serif;
									font-weight: normal;
									font-size: 14px;

									&::before {
										content: '';
										background: $white;
										border: 1px solid #252525;
										font-size: 24px;
										position: absolute;
										left: -25px;
										top: -5px;
										height: 20px;
										width: 20px;
									}
								}
							}
						}
					}
				}
			}

			.confirmation {
				margin-top: 20px;

				.confirmation-header {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					color: $white;
					background: #8e8e8e;
					padding: 10px 10px;
				}

				.confirmation-wrapper {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 12px;
					line-height: 1.2;
					color: #252525;
					border: 1px solid #8e8e8e;
					border-top: 0;
					display: flex;
					padding: 20px;
					align-items: center;

					div {
						flex: 1;
						display: flex;
						justify-content: center;
						padding: 0 10px;
					}

					.confirmation-image {
						img {
							align-self: center; //FIX chrome
							width: 100%; //FIX IE
						}
					}

					.confirmation-name {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						color: #252525;
						text-transform: uppercase;
					}

					.confirmation-data {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						flex-direction: column;

						p {
							font-weight: 500;

							span {
								font-weight: 100;
							}
						}
					}
				}
			}

			.message-success {
				display: none;

				.message-success-wrapper {
					display: flex;
					height: 300px;
					align-items: center;
					justify-content: center;

					p {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 12px;
						color: #252525;
						line-height: 1.3;
						text-align: center;
						width: 50%;
						font-weight: 600;

						span {
							font-weight: 100;
						}
					}
				}
			}
		}

		.product-order-btns {
			margin-top: 20px;
			text-align: center;

			.prev {
				font-family: Gotham, sans-serif;
				font-weight: 500;
				background: #8e8e8e;
				border: 0;
				border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 20px;
				display: none;
			}

			.next {
				font-family: Gotham, sans-serif;
				font-weight: 500;
				background: #ed1c24;
				border: 0;
				border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 20px;
			}
		}
	}
}

.products-v2 {
	@include size(100%, auto);
	padding: 40px 30px;
	background-color: $white;

	.title {
		text-transform: uppercase;
	}

	.search-container {
		width: 100%;
		padding: 0 0 20px 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		.search {
			display: flex;
			align-items: center;
			border: 1px solid $purpleDarkV2;
			border-radius: 25px;
			padding: 5px 12px;

			@media (min-width: 1200px) {
				width: calc(25% - 20px);
			}

			form {
				@include size(100%, auto);
				display: flex;
				justify-content: space-between;
				align-items: center;

				input {
					font-family: $uniformCond;
					font-weight: 500;
					font-size: 12px;
					color: $purpleDarkV2;
					background: none;
					border: 0;
					padding: 0;
					margin: 0;
					flex-grow: 1;

					&::placeholder {
						color: $purpleV2;
					}
				}

				button {
					font-size: 14px;
					background: none;
					border: 0;
					margin: 0;
					padding: 0;
					color: $bordeauxV2;
				}
			}
		}

		.filters-bar {
			display: none;
			font-family: $uniformExCond;
			font-weight: 500;
			font-size: 14px;
			text-align: right;
			width: 75%;

			span {
				color: $orangeV2;
			}

			ul {
				display: inline-block;
				margin: 0 0 0 6px;

				li {
					display: inline-block;

					a {
						border: 0;
						border-radius: 3px;
						color: $purpleDarkV2;
						cursor: pointer;
						margin: 0 4px;
						padding: 2px 8px 4px;
						text-decoration: none;
					}

					&:last-child {
						a {
							margin: 0 0 0 4px;
						}
					}
				}

				.selected {
					a {
						text-decoration: underline;
						font-weight: 800;
					}
				}
			}

		}
	}

	aside {
		.menu {
			.head {
				position: relative;

				// .products-link,
				// .experiences-link {
				// 	display: inline-block;
				// 	position: absolute;
				// 	top: 5px;
				// 	color: $white;
				// 	font-size: 16px;
				// 	background: none;
				// 	border: 0;
				// 	margin: 0 2px;
				// 	padding: 2px;
				// 	opacity: .6;
				// 	cursor: pointer;
				// }

				// .products-link {
				// 	right: 28px;
				// }

				// .experiences-link {
				// 	right: 6px;
				// }

				.drop {
					display: none;
					position: absolute;
					top: 0;
					color: $white;
					font-size: 20px;
					background: 0 0;
					border: 0;
					margin: 0 2px;
					padding: 0;
					cursor: pointer;
					right: 5px;
				}

				.selected {
					opacity: 1;
				}
			}
		}
	}

	.filters-bar-responsive {
		width: 50%;
		display: none;

		select {
			color: $white;
			background-color: #5a5a5a;
			padding: 6px 10px;
			width: 100%;
			position: relative;
			display: block;
			font-weight: 100;
		}
	}

	&__container {

		display: block;
		clear: both;

		@media (min-width: 1200px) {
			display: flex;
		}

		&-aside {
			flex-basis: 25%;
			margin-bottom: 30px;

			@media (min-width: 1200px) {
				margin-bottom: 0;
				margin-right: 20px;
			}

			.text {
				text-transform: uppercase;
				background-color: $purpleDarkV2;
				border-top: 1px solid $purpleV2;
				border-left: 1px solid $purpleV2;
				border-right: 1px solid $purpleV2;
				padding: 5px 10px;
			}

			.menu {
				ul {
					border: 1px solid $purpleV2;
					border-top: 0;
					@include size(100%, auto);

					li {
						border-top: 1px solid $purpleV2;

						&:first-of-type {
							border-top: 0;
						}

						&.subcategorie {
							text-indent: 1em
						}

						&.sub-subcategorie {
							text-indent: 2em
						}

						a {
							font-family: $uniform;
							font-weight: 500;
							color: $purpleV2;
							font-size: 12px;
							padding: 7px 10px;
							display: block;
							cursor: pointer;
							width: 100%;
							text-decoration: none;

							&:hover {
								color: $purpleDarkV2;
							}

							span {
								margin-left: 5px;
							}
						}
					}
				}
			}
		}

		&-main {
			flex-basis: 75%;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;

		&--prizes {
			background-color: $purpleV2;
			padding: 40px 40px;
		}

		&--banner {
			@include relative;

			img {
				display: block;
				height: auto;

				&:last-of-type {
					@include absolute(top 50% left 50%);
					transform: translate(-50%, -50%);
					max-width: 350px;
					padding: 0 30px;
				}
			}
		}
	}

	&__item {
		flex-basis: 33%;
		padding: 40px;

		a {
			text-decoration: none;
		}

		&-img {
			border-radius: 50%;
			margin-bottom: 20px;
		}

		.text {
			text-transform: uppercase;
		}
	}

	.main {
		.highlights {
			width: 100%;
		}
	}

	.table {
		border: 1px solid $purpleV2;
		display: none;

		.table-header {
			background: $purpleDarkV2;
			padding: 5px 10px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;

			.breadcrumbs {
				display: inline-block;

				&.text {
					width: auto;
				}

				li {
					display: inline-block;
					cursor: pointer;
					text-transform: uppercase;
				}
			}

			.favorites-widget {
				a {
					font-family: $uniform;
					font-weight: 600;
					color: $white;
					font-size: 16px;
					border: 0;
					margin: 0;
					padding: 0;
					text-decoration: none;
					text-transform: uppercase;

					img {
						margin-right: 5px;
					}
				}
			}
		}

		.product-list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			.item {
				height: 100%;
				max-height: 140px;
				flex-basis: 50%;
				border-right: 1px solid $purpleV2;
				border-bottom: 1px solid $purpleV2;
				background-color: #F2F2F2;
				cursor: pointer;
				display: flex;
				padding: 20px 40px;

				.item-image {
					img {
						display: block;
						@include size(90px);
						border-radius: 50%;
						object-fit: contain;
						background-color: $white;
						margin-right: 40px;
					}
				}

				.item-contents {
					font-family: $uniformExCond;
					font-weight: 600;
					font-size: 18px;
					color: $purpleDarkV2;
					text-transform: uppercase;
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.item-name {
						color: $purpleDarkV2;
						text-transform: uppercase;
					}

					.item-brand {
						color: $purpleDarkV2;
						text-transform: uppercase;
					}

					.item-rate {
						color: $purpleDarkV2;
						margin-top: 10px;
					}

					.item-points {
						color: $purpleDarkV2;
						margin-top: 10px;

						span {
							font-size: 18px;
						}
					}
				}

				&:nth-of-type(2n) {
					border-right: none;
				}
			}

			.empty {
				cursor: default;

				&:hover {
					border: 1px solid $white;
					border-bottom: 1px solid #8e8e8e;
					border-right: 1px solid #8e8e8e;
				}
			}
		}

		.paginator {
			border-top: 1px solid $purpleV2;
			padding: 20px;
			margin-top: -1px;

			span {
				font-family: $uniformExCond;
				font-weight: 600;
				font-size: 18px;
				color: $purpleDarkV2;
				display: inline-block;
				margin-right: 14px;
			}

			ul {
				display: inline-block;

				li {
					display: inline-block;
					margin: 0 3px;

					a {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						font-size: 10px;
						color: $white;
						background: #e0e0e0;
						border: 0;
						cursor: pointer;
						margin: 0;
						padding: 3px 6px 2px;
						min-width: 20px;
						text-decoration: none;
					}
				}

				.selected {
					a {
						background: $red;
					}
				}
			}
		}
	}

	.product-detail {
		width: 100%;
		display: none;

		.product-detail-header {
			border: 1px solid $purpleV2;
			padding: 5px 10px;
			background-color: $purpleDarkV2;
			display: flex;
			justify-content: space-between;
			align-items: center;

			h2 {
				font-family: $uniform;
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				color: $orangeV2;
				text-transform: uppercase;
				display: inline-block;
				width: auto;
				padding: 0;
			}

			.favorites-widget {
				a {
					font-family: $uniform;
					font-weight: 600;
					color: $white;
					font-size: 16px;
					border: 0;
					margin: 0;
					padding: 0;
					text-decoration: none;
					text-transform: uppercase;
					display: flex;
					align-items: center;

					img {
						margin-right: 5px;
					}
				}
			}
		}

		.product-detail-container {
			border: 1px solid $purpleV2;
			background-color: #F2F2F2;
			border-top: 0;
			display: flex;
			padding: 20px;

			.product-detail-image {
				width: calc(100%/3);
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					@include size(100%, auto);
					display: block;
					border-radius: 50%;
					max-width: 200px; //FIX IE
				}
			}

			.product-detail-description {
				width: 33.33%;
				flex: 1;
				min-height: 0;
				min-width: 0;
				padding: 20px;

				h2 {
					font-size: 18px;
					line-height: 20px;
					text-transform: uppercase;
					margin-bottom: 10px;
					font-weight: 800;
				}

				p {
					font-weight: 500;
				}

			}

			.product-detail-data {
				width: 33.33%;
				position: relative;

				.data-reference {
					font-family: Gotham, sans-serif;
					font-weight: 500;
					font-size: 14px;
					color: #252525;
					padding: 20px 20px 20px 0;
					position: absolute;
					top: 0;
				}

				.data-wrapper {
					padding: 20px 20px 20px 0;
					width: 100%;

					.points {
						font-family: $uniformExCond;
						font-weight: 600;
						font-size: 30px;
						color: $orangeDarkV2;
						text-transform: uppercase;
					}

					.stock {
						font-family: $uniform;
						font-weight: normal;
						font-size: 12px;
						color: #878787;
						line-height: 1.2;

						span {
							font-weight: bold;
							color: #252525;
						}
					}

					.available-points {
						font-family: $uniform;
						font-weight: 700;
						font-size: 12px;
						color: $purpleDarkV2;
						line-height: 14px;
						margin-top: 20px;

						span {
							font-weight: 500;
						}
					}

					.request-rate {
						font-family: $uniform;
						font-weight: 500;
						font-size: 12px;
						color: $purpleDarkV2;
						line-height: 14px;

						span {
							display: inline-block;
						}
					}

					.change-points {
						display: flex;
						justify-content: space-between;
						align-items: center;

						&.btn {
							padding: 10px 25px;
							margin: 20px 0 15px;
							font-size: 14px;
							line-height: 14px;
						}

						span {
							font-weight: 600;
							margin-left: 5px;
						}
					}

					.add-favorite {
						display: flex;
						justify-content: space-between;
						align-items: center;

						&.btn {
							background-color: #C5C5C7;
							padding: 10px 25px;
							font-size: 14px;
							line-height: 14px;
						}

						span {
							font-weight: 600;
							margin-left: 5px;
						}
					}

					.favorite {
						background: #ececec;
					}
				}
			}
		}

		.product-detail-other-images {
			border: 1px solid #8e8e8e;
			border-top: 0;
			height: 150px;
			overflow: hidden;
			position: relative;

			.container {
				width: 100%;
				height: 100%;

				.slider {
					transform: translateX(0px);
				}
			}
		}
	}

	.product-order {
		padding: 30px;
		display: none;
		background-color: $purpleDarkV2;

		.product-order-header {
			ul {
				display: flex;
				justify-content: space-around;
				width: 100%;
				background-color: $bordeauxV2;

				li {
					font-family: $uniform;
					font-weight: 600;
					color: $white;
					font-size: 14px;
					flex: 100%;
					padding: 12px 0 10px;
					position: relative;
					text-align: center;
					text-transform: uppercase;

					&::after {
						content: '>';
						width: 20px;
						height: 37px;
						position: absolute;
						top: 0;
						right: 5px;
						font-size: 30px;
						font-family: monospace;
						color: $white;
					}

					&:last-child {
						&::after {
							content: ' ';
						}
					}
				}

				.selected {
					color: $orangeV2;
				}
			}
		}

		.product-order-step {
			.information {
				margin-top: 20px;

				.information-header {
					font-family: $uniform;
					font-weight: 600;
					font-size: 12px;
					color: $white;
					background: $orangeDarkV2;
					padding: 10px 20px;
					text-transform: uppercase;
				}

				.information-content {
					font-family: $uniform;
					font-weight: 500;
					font-size: 12px;
					line-height: 1.2;
					color: $white;
					border-top: 0;
					padding: 10px 20px;
					background: $purpleV2;
					border: 0;

					p {
						color: $white;

						&:last-child {
							font-weight: 100;
						}
					}
				}
			}

			.order {
				margin-top: 20px;

				.order-header {
					font-family: $uniform;
					font-weight: 600;
					font-size: 12px;
					color: $white;
					background: $orangeDarkV2;
					padding: 10px 20px;
				}

				.order-content {
					display: flex;
					overflow: hidden;
					padding: 20px;
					background-color: #F2F2F2;

					>div {
						flex-basis: 33.33%;
						padding: 0 30px;
						display: flex;
						align-items: center;
					}

					img {
						width: 100%; //FIX IE
						border-radius: 50%;
						background-color: $white;
					}

					.name {
						font-family: $uniformExCond;
						font-weight: 500;
						font-size: 18px;
						color: $purpleDarkV2;
						text-transform: uppercase;
					}

					.points {
						p {
							font-family: $uniformExCond;
							font-weight: 500;
							font-size: 30px;
							color: $orangeV2;
							text-transform: uppercase;
						}
					}
				}
			}

			.address {
				margin-top: 20px;

				.address-header {
					font-family: $uniform;
					font-weight: 600;
					font-size: 12px;
					color: $white;
					background: $orangeDarkV2;
					padding: 10px 20px;
				}

				.address-wrapper {
					font-family: $uniform;
					font-weight: normal;
					font-size: 12px;
					line-height: 1.2;
					color: $purpleDarkV2;
					border: 0;
					border-top: 0;
					padding: 50px 20px;
					background: #F2F2F2;

					.address-content {
						font-family: $uniform;
						font-weight: 700;
						font-size: 18px;
						display: flex;
						flex-direction: column;
						align-items: center;
						text-transform: uppercase;

						div {
							width: 70%;

							p {
								span {
									font-weight: 400;
								}
							}
						}
					}

					.checkboxes {
						margin: 20px auto 10px;
						width: 70%;
						padding-left: 25px;

						.checkboxes-wrapper {
							display: inline-block;
							text-align: left;

							input[type="checkbox"] {
								display: none;

								&:checked {
									+label {
										&::before {
											background-color: $orangeDarkV2;
										}

										&::after {
											content: '';
											@include absolute(top 0 left -21px);
											@include size(6px, 9px);
											border-bottom: 2px solid $white;
											border-right: 2px solid $white;
											transform: rotate(45deg);
										}
									}
								}

								+label {
									display: inline-block;
									position: relative;
									font-family: $uniform;
									color: $purpleDarkV2;
									font-weight: 500;
									text-transform: uppercase;
									font-size: 12px;


									&::before {
										content: '';
										border: 1px solid $orangeDarkV2;
										position: absolute;
										left: -25px;
										top: -1px;
										height: 14px;
										width: 14px;
										border-radius: 3px;
									}
								}
							}
						}
					}
				}
			}

			.confirmation {
				margin-top: 20px;

				.confirmation-header {
					font-family: $uniform;
					font-weight: 600;
					font-size: 12px;
					color: $white;
					background: $orangeDarkV2;
					padding: 10px 20px;
					text-transform: uppercase;
				}

				.confirmation-wrapper {
					display: flex;
					padding: 50px 20px;
					align-items: center;
					background: #F2F2F2;

					div {
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 30px;
					}

					.confirmation-image {
						img {
							align-self: center; //FIX chrome
							width: 100%; //FIX IE
							max-width: 150px;
							border-radius: 50%;
							background-color: $white;
						}
					}

					.confirmation-data {
						flex-grow: 1;
						font-family: $uniform;
						font-weight: 700;
						font-size: 18px;
						flex-direction: column;
						align-items: flex-start;
						text-transform: uppercase;

						p {
							span {
								font-weight: 400;
							}
						}
					}
				}
			}

			.message-success {
				display: none;

				.message-success-wrapper {
					display: flex;
					height: 300px;
					align-items: center;
					justify-content: center;
					background: #F2F2F2;

					p {
						font-family: $uniform;
						font-weight: 700;
						font-size: 18px;
						color: $purpleDarkV2;
						text-transform: uppercase;
						line-height: 1.3;
						text-align: center;
						width: 50%;

						span {
							font-weight: 400;
						}
					}
				}
			}
		}

		.product-order-btns {
			margin-top: 20px;
			text-align: center;
			display: flex;
			justify-content: center;

			.prev {
				font-family: $uniform;
				font-weight: 500;
				background: #8e8e8e;
				border: 0;
				border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 20px;
				display: none;
			}

			.next {
				font-family: $uniform;
				font-weight: 500;
				background: #ed1c24;
				border: 0;
				border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 20px;
			}
		}
	}
}