.user {
	&-data {
		overflow: hidden;
		display: block;
		position: relative;
		width: 100%;
		box-shadow: $box-shadow;
		background-color: white;

		.table-user-data {
			overflow: hidden;
			position: relative;
			display: flex;
			flex-wrap: wrap;

			.input-wrapper {
				padding: 4px;

				.container {
					background: $white;
					display: table;
					padding: 4px 8px;
					width: 100%;

					label {
						font-family: $uniform;
						font-weight: 600;
						font-size: 14px;
						line-height: 16px;
						vertical-align: top;
						width: 1px;
						display: table-cell;
						margin: 0;
						overflow: hidden;
						padding: 0;
						white-space: nowrap;
						color: $purpleDarkV2;
					}

					input {
						font-family: $uniform;
						font-weight: normal;
						font-size: 14px;
						line-height: 16px;
						background: none;
						border: 0;
						padding: 0;
						margin: 0 4px 0;
						width: 100%;
						display: table-cell;
						margin: 0;
						overflow: hidden;
						padding: 0;
						vertical-align: top;
						color: $purpleDarkV2;
					}

					textarea {
						max-height: 160px;
						overflow-x: hidden;
						overflow-wrap: break-word;
						resize: none;
						height: 48px;
						width: 100%;
						font-family: $uniform;
						font-weight: normal;
						font-size: 14px;
						line-height: 16px;
						background: none;
						border: 0;
						padding: 0;
						margin: 0 4px 0;
						color: $purpleDarkV2;
					}

					&.disabled {
						background: #ececec !important;
					}
				}

				&--col1 {
					flex-basis: 100%;

					@media screen and (min-width: 576px) {
						flex-basis: 50%;
					}

					@media screen and (min-width: 768px) {
						flex-basis: 25%;
					}
				}

				&--col2 {
					flex-basis: 50%;
				}

				&--col3 {
					flex-basis: 100%;

					@media screen and (min-width: 768px) {
						flex-basis: 75%;
					}
				}

				&--col4 {
					flex-basis: 100%;
				}
			}

			.observations {
				display: none;
			}
		}

		.btn {
			padding: 20px 0;
			text-align: center;
			margin-top: 5px;

			a {
				font-family: Gotham, sans-serif;
				font-weight: 500;
				background: #ed1c24;
				border: 0;
				border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 10px;
			}
		}

		.checkboxes {
			margin: 20px 0 10px;
			text-align: center;
			display: none;

			.checkboxes-wrapper {
				display: inline-block;
				text-align: left;

				input[type="radio"] {
					display: none;
				}

				input[type="radio"]+label {
					background: $white;
					border: 1px solid #252525;
					display: inline-block;
					height: 20px;
					margin-bottom: 3px;
					position: relative;
					vertical-align: middle;
					width: 20px;

					&::before {
						content: ' ';
						font-size: 24px;
						position: absolute;
						left: 2px;
						top: -3px;
					}
				}

				input[type="radio"]:checked+label {
					&::before {
						content: 'X';
					}
				}

				label {
					font-family: "Gotham Book", sans-serif;
					font-weight: normal;
					font-size: 14px;
				}
			}
		}

		.confirm-panel {
			padding: 20px 0;
			text-align: center;
			display: none;

			a {
				font-family: Gotham, sans-serif;
				font-weight: 500;
				background: #ed1c24;
				border: 0;
				border-radius: 50px;
				color: $white;
				font-size: 14px;
				padding: 10px 34px 8px;
				cursor: pointer;
				text-decoration: none;
				text-transform: uppercase;
				margin: 0 10px;
			}
		}

		.title {
			text-transform: uppercase;
		}

		&__row {
			padding: 40px 30px;

			&:last-of-type {
				background-color: $purpleV2;
			}
		}
	}

	&-points {
		padding: 40px 30px;
		width: 100%;
		background-color: white;
		display: flex;
		flex-wrap: wrap;
		.points-container {
			color: $white;
			@include size(90px);
			border-radius: 50%;
			background-color: $orange;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			span {
				font-weight: 700;
			}
		}

		.btns {
			margin: 50px 0 10px;

			a {
				background: $white;
				border: 1px solid #8e8e8e;
				border-radius: 10px;
				cursor: pointer;
				margin: 0px 10px;
				padding: 8px 10px 6px;
				transition: background .4s, border .4s, color .4s;
				font-family: "Gotham Book", sans-serif;
				font-weight: normal;
				font-size: 14px;
				color: #878787;
				text-decoration: none;

				&:first-child {
					margin: 0 10px 0 0;
				}

				&:hover {
					background: #ed1c24;
					border-color: #ed1c24;
					color: $white;
				}
			}

			.active {
				background: #8e8e8e;
				color: $white;
			}
		}

		table {
			width: 100%;
			thead {
				tr {
					background: $purpleV2;

					th {
						color: $orangeV2;
						padding: 8px 0 6px;
						font-weight: 600;
						text-transform: uppercase;
					}
				}
			}

			tbody {
				tr {
					&:nth-child(even) {
						background: rgba($orangeV2, .2);
					}

					&:nth-child(odd) {
						background: white;
					}

					td {
						padding: 8px 0 6px;
						text-align: center;
						font-family: $uniform;
						font-weight: normal;
						font-size: 14px;
						color: $purpleDarkV2;

						i {
							cursor: pointer;
						}
					}
				}
			}
		}

		.pagination {
			margin: 20px -10px;
			text-align: center;
		}

		.tableResponsive {
			tbody {
				.odd {
					background-color: #f7f7f7;
				}

				.even {
					background-color: #efefef;
				}

				th {
					text-align: right;
					font-size: 14px;
					color: #5e5e5e;
					font-weight: 500;
					width: 50%;
					padding: 10px;
				}

				td {
					text-align: left;
					width: 50%;
					padding: 10px;
					font-family: "Gotham Book", sans-serif;
					font-weight: 400;
					font-size: 14px;
					color: #878787;
				}
			}
		}
		.prize-status {
			background: rgba(255, 255, 255, 0.8);
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 3;
			display: none;
	
			.status-wrapper {
				background: $white;
				box-shadow: $box-shadow;
				margin: 150px auto 0;
				padding: 20px 10px;
				width: 500px;
				height: 180px;
	
				svg {
					height: 80px;
					width: 100%;
					padding: 0;
					font: normal 9pt sans-serif;
	
					line {
						stroke: #ececec;
						stroke-width: 4px;
					}
	
					circle {
						fill: #8e8e8e;
					}
	
					text {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						fill: #8e8e8e;
						text-anchor: middle;
					}
				}
	
				.status-btn {
					position: relative;
					display: block;
					width: 100%;
					text-align: center;
					margin: 30px 0 10px 0;
	
					.close {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						background: #ed1c24;
						border: 0;
						border-radius: 50px;
						color: $white;
						font-size: 14px;
						padding: 10px 34px 8px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						margin: 10px 0;
					}
				}
			}
		}
		&__row {
			@include size(100%, auto);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 30px;
			&:last-of-type {
				margin-bottom: 0;
			}
			.title {
				text-transform: uppercase;
				width: auto;
				margin-right: 20px;
				margin-bottom: 0;
			}
		}
	}

	&-prizes {
		padding: 40px 30px;
		width: 100%;
		overflow: hidden;
		display: block;
		position: relative;
		width: 100%;
		background-color: white;
	
		.movements {
			width: 100%;
			margin-top: 20px;
			margin-top: 20px;
	
			thead {
				tr {
					background: $purpleV2;

					th {
						color: $orangeV2;
						padding: 8px 0 6px;
						font-weight: 600;
						text-transform: uppercase;
						font-size: 14px;
					}
				}
			}
	
			tbody {
				tr {
					&:nth-child(even) {
						background: rgba($orangeV2, .2);
					}
	
					&:nth-child(odd) {
						background: white;
					}
	
					td {
						padding: 8px 0 6px;
						text-align: center;
						font-family: $uniform;
						font-weight: normal;
						font-size: 14px;
						color: $purpleV2;
	
						p {
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;
	
							i {
								color: #ed1c24;
							}
						}
					}
				}
			}
		}
	
		.pagination {
			margin: 20px -10px;
			text-align: center;
		}
	
		.tableResponsive {
			width: 100%;
	
			tbody {
				.odd {
					background-color: #f7f7f7;
				}
	
				.even {
					background-color: #efefef;
				}
	
				th {
					text-align: right;
					font-size: 14px;
					color: #5e5e5e;
					font-weight: 500;
					width: 50%;
					padding: 10px;
				}
	
				td {
					text-align: left;
					width: 50%;
					padding: 10px;
					font-family: "Gotham Book", sans-serif;
					font-weight: 400;
					font-size: 14px;
					color: #878787;
	
					p {
						cursor: pointer;
	
						i {
							color: #ed1c24;
						}
					}
				}
			}
		}
		.prize-status {
			background: rgba(255, 255, 255, 0.8);
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 3;
			display: none;
		
			.status-wrapper {
				background: $white;
				box-shadow: $box-shadow;
				margin: 150px auto 0;
				padding: 20px 10px;
				width: 500px;
				height: 180px;
		
				svg {
					height: 80px;
					width: 100%;
					padding: 0;
					font: normal 9pt sans-serif;
		
					line {
						stroke: #ececec;
						stroke-width: 4px;
					}
		
					circle {
						fill: #8e8e8e;
					}
		
					text {
						font-family: "Gotham Book", sans-serif;
						font-weight: normal;
						fill: #8e8e8e;
						text-anchor: middle;
					}
				}
		
				.active {
					circle {
						fill: #ed1c24;
					}
		
					text {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						fill: #ed1c24;
					}
		
		
					
					line {
						stroke: #ed1c24;
					}
		
					&.first, &+.last {
						line {
							stroke: #ececec;
						}
					}
				}
		
				.status-btn {
					position: relative;
					display: block;
					width: 100%;
					text-align: center;
					margin: 30px 0 10px 0;
		
					.close {
						font-family: Gotham, sans-serif;
						font-weight: 500;
						background: #ed1c24;
						border: 0;
						border-radius: 50px;
						color: $white;
						font-size: 14px;
						padding: 10px 34px 8px;
						cursor: pointer;
						text-decoration: none;
						text-transform: uppercase;
						margin: 10px 0;
					}
				}
			}
		}

		.title {
			text-transform: uppercase;
		}

		&__icon {
			&-info {
				margin-left: 5px;
				display: inline-block;
				@include size(16px);
				background: url('/assets/img/icons/ico-info.svg') no-repeat center / cover;
			}
		}
	}
}
