.title-text {
	@include size(100%, auto);
	background-color: $white;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	box-shadow: $box-shadow;
	@include padding($short: 40px 30px);

	&__list {
		@include size(100%, auto);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&--ordered {
			counter-reset: list;
		}

		&-item {
			width: calc(50% - 10px);
			background-color: $white;
			box-shadow: $box-shadow;
			@include margin($bottom: 20px);
			@include padding($short: 15px);

			&:nth-last-of-type(-n+2) {
				@include margin($bottom: 0);
			}

			&::before {
				.title-text__list--ordered & {
					counter-increment: list;
					content: counter(list) '.';
					display: block;
					font-size: 31px;
					font-weight: 500;
					color: $red-light;
					@include margin($bottom: 10px);
				}
				.title-text__list--bullets & {
					content: '\2022';
					@include absolute(top 4px left 0);
					color: $red;
					display: inline-block;
				}
			}

			.title-text__list--bullets & {
				width: 100%;
				background-color: transparent;
				box-shadow: none;
				@include padding($short: 0 0 0 15px);
				@include margin($short: 0);
				display: flex;
				@include relative;
			}

			.title {
				@include margin($bottom: 5px);
			}
		}
	}

	&__icon {
		@include size(50px);
		background: $red;
		border-radius: 50%;
		figure & {
			@include margin($bottom: 20px);
		}
	}

	&--background-full {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		min-height: 500px;
		&.title-text--chest {
			align-items: center;
			justify-content: flex-end;
			padding: 30px 40px 55px;
			.title {
				max-width: 70%;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
		}
	}
}