.list {
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	@include margin($top: 20px);
	color: $gray;
	&__title {
		font-size: 31px;
		font-weight: 700;
		line-height: 40px;
		color: $gray;
		flex-grow: 1;
		.layout-v2 & {
			font-weight: 900;
			color: $purpleDarkV2;
		}
	}
	&__filter {
		@include size(40px);
		border-radius: 50%;
		background-color: $gray;
		position: relative;
		&::after {
			content: '';
			@include size(40px);
			@include absolute(top 50% left 50%);
			transform: translate(-50%, -50%);
			background: url('/assets/img/icons/ico-settings.svg') no-repeat center / cover;
		}
	}
	&__container {
		flex: 1 0 100%;
		@include margin($top: 30px);
		&.list--inner {
			@include margin($top: 0);
		}
		&--no-margin {
			@include margin($top: 0);
		}
	}
	&__item {
		background: white;
		box-shadow: $box-shadow;
		@include margin($bottom: 20px);
		&:last-of-type {
			@include margin($bottom: 0);
		}
		&-text {
			flex-grow: 1;
			line-height: 25px;
		}
		&.pending {
			box-shadow: inset 0px 0px 0px 2px $orange;
		}
		&.invalid {
			box-shadow: inset 0px 0px 0px 2px $red-light;
		}
		&-state {
			font-size: 10px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 25px;
			letter-spacing: .5px;
			color: white;
			@include size(auto, 25px);
			min-width: 110px;
			@include padding($short: 0 10px);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.pending & {
				background-color: $orange;
			}
			.invalid & {
				background-color: $red-light;
			}
			.valid & {
				background-color: $green;
			}
			.standby & {
				background-color: $gray-light;
			}
		}
		&-text {
			.accordeon & {
				@include margin($bottom: 15px);
			}
		}
		.list--inner & {
			box-shadow: none;
		}
	}
	&__row {
		display: flex;
		flex-wrap: wrap;
		@include padding($short: 25px 30px);
		&:nth-of-type(2){
			display: none;
			background-color: rgba(173,173,173,0.1);
			@include padding($short: 25px 30px);
			.common & {
				background-color: transparent;
				@include padding($short: 0 30px 40px);
				.list--inner & {
					@include padding($short: 0)
				}
			}

			.is-chart & {
				background-color: transparent;
				@include padding($short: 0 30px 40px);
			}
		}
		&:not(.list__column--accordeon) {
			.pending &,
			.invalid & {
				cursor: pointer;
			}
		}
		&-field {
			@include size(100%, auto);
			flex-grow: 1;
			display: flex;
			flex-wrap: wrap;
			@include margin($bottom: 10px);
			text-decoration: none;
			color: $gray;
			&:last-of-type:not(.list__row-field--margin-big) {
				@include margin($bottom: 0);
			}
			&--margin-big {
				@include margin($bottom: 30px);
			}
			&--border-top {
				border-top: 1px solid #D9D9D9;
				@include padding($top: 25px);
			}
			&--space-between {
				justify-content: space-between;
			}
			&--border-bottom {
				@include margin($bottom: 0);
				@include padding($short: 15px 0);
				border-bottom: 1px solid #D9D9D9;
				&:first-of-type {
					@include padding($top: 0);
				}
			}
			&--padding-top {
				@include padding($top: 15px);
			}
			.list__column--valign-center & {
				flex-grow: 0;
				width: 100%;
			}
			.list--inner & {
				width: auto;
			}
			&--icon {
				@include relative;
				&::after {
					content: '';
					@include absolute(top 50% right 0);
					transform: translateY(-50%);
					@include size(40px);
					background: url('/assets/img/icons/ico-view-detail.svg') no-repeat center / cover
				}
			}
		}
		&-message {
			font-weight: 500;
		}
		.common & {
			.list--inner & {
				@include padding($short: 0);
			}
		}
		.is-chart &,
		.common & {
			@include padding($short: 40px 30px);
		}
		// &--accordeon-open {
		// 	display: block!important;
		// }
	}
	&__column {
		@include size(50%, auto);
		@include padding($right: 20px);
		&:nth-of-type(2n) {
			@include padding($left: 20px, $right: 0);
		}
		&--valign-center {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
		}
		&--border-left {
			border-left: 1px solid #D9D9D9;
		}
	}
	&__text {
		@include size(auto);
		font-size: 16px;
		line-height: 19px;
		flex-grow: 1;
		font-weight: 400;
		.layout-v2 & {
			font-weight: 700;
			color: $purpleDarkV2;
		}

		&--date {
			width: 100%;
			font-size: 12px;
			font-weight: 500;
			color: $gray-light;
			text-transform: uppercase;
			@include margin($bottom: 5px);
		}
		&--points {
			flex-grow: 0;
			font-weight: 500;
			.layout-v2 & {
				font-weight: 700;
			}
		}
		&--full-width {
			width: 100%;
		}
		&--big {
			font-size: 31px;
			line-height: 37px;
		}
	}
	&__toggle {
		@include size(40px);
		background-color: #EAECEF;
		border-radius: 50%;
		position: relative;
		&::after {
			content:'';
			@include size(40px);
			color: $gray;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 40px;
			font-weight: 400;
			background: url('/assets/img/icons/ico-plus.svg') no-repeat center / cover;
		}
		&.close {
			&::after {
				background: url('/assets/img/icons/ico-minus.svg') no-repeat center / cover;	
			}
		}
	}
	&__link {
		font-size: 16px;
		color: $gray;
		font-weight: 700;
		display: inline-block;
		@include margin($top: 40px);
		text-transform: uppercase;
		.layout-v2 & {
			color: $purpleDarkV2;
		}
	}
	&__navigation {
		@include size(100%, auto);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include margin($top: 30px);

		&-prev {
			@include size(40px);
			background-color: $gray;
			border-radius: 50%;
			@include relative;
			.layout-v2 & {
				background-color: $purpleDarkV2;
			}
			&::after {
				content: '';
				@include absolute;
				@include size(40px);
				background: url('/assets/img/icons/ico-arrow.svg') no-repeat center / cover;
			}
			&.disabled {
				background-color: #D6DADF;
			}
		}
		&-next {
			@include size(40px);
			background-color: $gray;
			border-radius: 50%;
			@include relative;
			.layout-v2 & {
				background-color: $purpleDarkV2;
			}
			&::after {
				content: '';
				@include absolute;
				@include size(40px);
				transform: rotate(180deg);
				background: url('/assets/img/icons/ico-arrow.svg') no-repeat center / cover;
			}
			&.disabled {
				background-color: #D6DADF;
			}
		}
		&-text {
			@include margin($short: 0 20px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 31px;
			color: $gray;
			font-weight: 500;
			.layout-v2 & {
				color: $purpleDarkV2;
			}
		}
	}
}