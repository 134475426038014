@media screen and (max-width: 1200px) {
	.header {
		max-width: 90%;
		min-height: 75px;
		padding: 10px;

		.header-wrapper {
			.logo {
				position: absolute;
				width: 100%;
				display: inline-block;
				float: none;
				text-align: center;
			}

			.menu {
				display: none;
			}

			.menu-trigger {
				display: block;
			}

			.cc-logo {
				top: 15px;
				text-align: right;

				img {
					width: 55px;
					height: auto;
				}
			}
		}
	}

	.wrapper {
		margin: 0 auto;
		max-width: 90%;
		padding: 0;
	}

	.slider {
		display: block;		

		.user-data {
			width: 100%;
		}

		.slide {
			.slide-container {
				height: auto;

				img {
					height: auto;
					width: 100%
				}
			}
		}
	}

	.video {
		.video-container {
			height: 200px;
		}
	}

	.shortcuts {
		ul {
			height: 200px;

			li {
				a {
					i {
						font-size: 40px;
					}
				}
			}
		}
	}

	.program {
		.step {
			.column {
				width: 100% !important;
				float: none !important;
			}

			.box {
				text-align: center;

				span {
					margin-bottom: 15px;
				}
			}
		}
	}

	.registration {
		.search-block {
			.left {
				float: none;
				width: 100%;

				select {
					width: 100%;
				}
			}

			.middle {
				float: none;
				width: 100%;

				p {
					padding: 10px 0;
				}
			}

			.right {
				float: none;
				width: 100%;

				input {
					width: 100%;
				}
			}
		}

		.table-user-data {
			.input-wrapper {
				width: 100% !important;
			}
		}
	}

	.simulator {
		.table-simulator {
			table {
				&:first-child {
					display: none;
				}
			}

			.tableResponsive {
				margin-top: 25px;
			}

			.add-field {
				button {
					width: 100%;
				}
			}
		}
	}

	.catalog {
		.catalog-container {

			.products-banner,
			.experiences-banner {
				background-position: left center;
			}
		}
	}

	.products {
		h2 {
			width: 50%;
			float: left;
		}

		.search-container {
			width: 50%;
			float: left;
			padding: 15px 0 20px;

			.search {
				width: 100%;
			}

			.filters-bar {
				display: none !important;
			}
		}


		aside {
			width: 100%;
			min-height: 50px;
			display: flex;

			.menu {
				width: 45%;
				margin-right: 5%;

				h2 {
					width: 100%;
				}

				.head {
					.products-link {
						right: 50px;
					}

					.experiences-link {
						right: 25px;
					}

					.drop {
						display: inline-block;
					}

					ul {
						display: none;
						position: absolute;
						z-index: 5;
						background-color: #fff;
						top: 30px;
						left: 0;
					}
				}
			}
		}

		.main {
			float: none;
			width: 100%;
			clear: both;
			padding: 0;

			.highlights {
				.img-block {
					background-position: left center;

					.text-container {
						h2 {
							float: none;
							width: auto;
						}
					}
				}

				.products-block {
					.product {
						padding: 30px;
						width: 50%;
					}
				}
			}

			.table {
				.product-list {
					.item {
						width: 100%;
						float: none;
					}
				}
			}

			.product-detail {
				.product-detail-header {
					height: 30px;
				}

				.product-detail-container {
					flex-wrap: wrap;
					padding-bottom: 0;

					.product-detail-image {
						width: 50%;
						padding-right: 20px;
					}

					.product-detail-description {
						order: 1;
					}

					.product-detail-data {
						width: 50%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		width: 100%;
	}

	.header {
		padding: 10px 20px;
		width: 100%;
	}

	.featured-prizes {
		.prizes {
			.item {
				width: 50%;
				padding: 30px;
			}
		}
	}

	.catalog {
		.catalog-container {

			.products-banner,
			.experiences-banner {
				.content-left {
					width: 50%;
				}

				h2 {
					font-size: 24px;
				}
			}
		}
	}
	.hero-cta {
		@include padding($short: 40px);
		&__title {
			font-size: 31px;

			&--big {
				font-size: 46px;
			}
		}
		&__text {
			&:last-of-type {
				@include margin($bottom: 30px);
			}
			&--small {
				font-size: 16px;
				line-height: 20px;
			}
			&--medium {
				font-size: 20px;
				line-height: 25px;
			}
			&--big {
				font-size: 30px;
				line-height: 34px;
			}
		}
	}
	.btn {
		@include padding($short: 12px 25px);
		font-size: 12px;
	}
	.title-cta {
		display: block;
		&__column {
			@include size(100%, auto);
			@include margin($bottom: 20px);
			@include padding($short: 40px);
		}
		&__title {
			font-size: 26px;
			@include margin($bottom: 40px);
			&::after {
				@include size(80px, 14px);
				bottom: -20px;
			}
		}
		&__subtitle {
			font-size: 20px;
			@include margin($bottom: 20px);
		}

		&__text {
			font-size: 16px;
			@include margin($bottom: 30px);
		}
	}
}

@media screen and (max-width: 600px) {
	.registration {
		.results {
			ul {
				li {

					p,
					a {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.title-cta {
		&__column {
			@include padding($short: 40px 20px);
		}
	}
	.hero-cta {
		@include padding($short: 40px 20px);
	}
}

@media screen and (max-width: 500px) {
	.video {
		.video-container {
			height: 110px;
		}
	}

	.shortcuts {
		ul {
			flex-wrap: wrap;
			height: 300px;

			li {
				flex: 0 50%;
				height: auto;
			}
		}
	}

	.program {
		.container {
			.box {
				text-align: center;
			}

			.table-container {
				table:first-child {
					display: none;
				}
			}
		}
	}

	.simulator {
		.table-simulator {
			.btn {
				a {
					width: 80%;
					display: block;
					margin: 0 auto;
				}
			}
		}
	}

	.featured-prizes {
		.prizes {
			.item {
				width: 100%;
				padding: 30px;
				float: none;
			}
		}
	}

	.catalog {
		.catalog-container {

			.products-banner,
			.experiences-banner {
				.content-left {
					width: 100%;
				}

				h2 {
					position: relative;
					bottom: 0;
					right: 0;
					top: 25px;
				}
			}
		}
	}

	.products {
		h2 {
			width: 100%;
			float: none;
		}

		.search-container {
			width: 100%;
			float: none;
		}

		aside {
			width: 100%;
			float: none;
			padding: 0;

			.menu {
				.head {
					h2 {
						text-align: left;
						font-size: 11px;
					}

					ul {
						li {
							a {
								font-size: 10px;
							}
						}
					}
				}
			}
		}

		.filters-bar-responsive {
			margin-bottom: 25px;
		}

		.main {
			.highlights {
				.img-block {
					.text-container {
						position: relative;
						text-align: center;
						width: 100%;

						h2 {
							width: 100%;
							text-align: center;
						}

						p {
							padding: 3px;
						}
					}
				}

				.products-block {
					.product {
						width: 100%;
					}
				}
			}

			.table {
				.table-header {
					.breadcrumbs {
						display: block;
						width: 100%;
					}

					.favorites-widget {
						display: block;
						float: none;
						margin-top: 15px;
						margin-bottom: 15px;
					}

					.paginator {
						span {
							&:first-child {
								display: block;
							}
						}
					}
				}
			}

			.product-detail {
				.product-detail-header {
					height: auto;

					.favorites-widget {
						display: block;
						float: none;
						margin-top: 10px;
					}
				}

				.product-detail-container {
					min-height: 500px;


					.product-detail-image {
						width: 100%;
						padding-right: 0;

						img {
							height: 150px;
						}
					}

					.product-detail-description {
						width: 100%;
						display: block;
						order: 0;
					}

					.product-detail-data {
						width: 100%;
						order: 1;

						.data-wrapper {
							padding: 20px;
							position: static;
						}
					}
				}
			}

			.product-order {
				.product-order-step {
					.order {
						.order-content {
							flex: none;
							display: block;
							width: 100%;
							height: auto;

							.img {
								display: block;
								flex: none;
								height: 150px;
							}

							.name {
								display: block;
								flex: none;
								margin-top: 25px;
							}

							.points {
								display: block;
								flex: none;
								margin-top: 15px;
								margin-bottom: 15px;
							}
						}
					}
				}
			}
		}
	}

	.campaigns {
		.images {
			div {
				float: none;
				width: 100%;
				margin-bottom: 30px;
			}
		}
	}

	.footer {

		.footer-wrapper ul {
			width: 100%;
			float: none;
			text-align: center !important;

			li {
				display: block;
				margin-bottom: 10px;
			}
		}
	}
}